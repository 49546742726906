@import '../node_modules/antd/dist/antd.less';
@import '../node_modules/@library/react-toolkit/src/index.less';


@primary-color: #FBBC05;
@success-color: #51b148;


.ant-switch-checked {
    background-color: #FBBC05 !important ;
}
@body-background: #f2f2f2;
@primary-color: #51b148;
@error-color: #f44336;
@secondary-color: #2660a4;
@layout-body-background: @body-background;

@layout-header-background: @primary-color !important;

@layout-header-height: 56px !important;

@border-radius-base: 4px;

// @border-radius-base: 8px;
@success-color: @primary-color !important;
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 32px;
}

.ant-card {
  border-radius: 16px;
  box-shadow: -4px -4px 12px 4px rgba(123, 123, 123, 0.04),
    4px 4px 12px 4px rgba(123, 123, 123, 0.04);
  & .ant-card-head {
    font-weight: 500;
    border: 0;
    font-size: 30px;
    padding: 0 32px;
  }
  & .ant-card-body {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
  }
}

.ant-modal-header {
  padding: 16px 32px;
  & .ant-modal-title {
    font-size: 24px;
  }
}

.ant-modal-body {
  padding: 16px 32px;
}

.ant-modal-footer {
  padding: 16px 32px 24px 32px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
hr {
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}
.ant-switch-checked {
  background-color: #51b148;
}
.userTable {
  vertical-align: baseline;
}

.ant-layout-header {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  & > .ant-menu {
    height: 56px;
    & > .ant-menu-item {
      height: 56px;
      line-height: 56px;
    }
  }
}

.ant-popover {
  z-index: 1051;
}
